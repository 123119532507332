<template>
  <el-form
    :model="dataForm"
    label-width="100px"
    class="login-form"
  >
    <el-form-item label="身份证">
      <el-input
        type="citizenId"
        v-model="dataForm.citizenId"
      ></el-input>
    </el-form-item>
    <el-form-item label="手机号">
      <el-input
        type="mobile"
        v-model="dataForm.mobile"
      ></el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="submitForm()">登录</el-button>
      <el-button type="" @click="register()">注册</el-button>
      <el-button type="success" style="position:relative;left:100px" @click="querycert()">证书查询</el-button>
    </el-form-item>
    <el-form-item>
      <!-- <div style="font-size:18px">首次登录前请先「注册」，2021年注册过的用户可直接「登录」</div> -->
       <div style="font-size:18px">本期学习已结束，如需查询证书请点击「证书查询」按钮</div>
    </el-form-item>
    <!-- <div style="display:none"> -->
    <div style="display:none">
    <el-image v-if="qrurl" style="width: 200px; height: 200px;" :src="qrurl"></el-image>
    <div v-if="checkQrLoginSec>0">请用微信扫码登录({{checkQrLoginSec}} 秒后失效)</div>
    <div v-if="checkQrLoginSec==0">登录码已失效，请刷新当前页面</div>
    </div>
  </el-form>
</template>

<script>
import store from '@/store/simpstore'
export default {
  data() {
    return {
      dataForm: {
        citizenId: "",
        mobile: "",
      },
      checkQrLoginSec: 60,
      qrurl: ""
    };
  },
  mounted: function () {
    // let apiBaseUrl = this.$net.apiBaseUrl
    // this.$net.loginforqr().then(({data}) => {
    //   let qrurl = apiBaseUrl + "loginforqr2/"+data.s
    //   this.qrurl = qrurl
    //   this.checkLogin = setInterval(()=>{
    //     this.$net.loginforqr4(data.s).then(({data}) => {
    //       let token = data.token
    //       if(token){
    //           this.$cookie.set('token',data.token)
    //           this.$router.replace('/')
    //           store.setUser(true)
    //       }
    //     })
    //   },3000)
    //   this.beatSec = setInterval(()=>{
    //     this.checkQrLoginSec = this.checkQrLoginSec - 1
    //     if(this.checkQrLoginSec == 0){
    //       this.stopCheckQRLogin()
    //     }
    //   },1000)
    // })
  },
  unmounted: function () {
    this.stopCheckQRLogin()
  },
  methods: {
    submitForm () {
        this.$net
        .login({ citizenId: this.dataForm.citizenId, mobile: this.dataForm.mobile })
        .then(({data}) => {
            if (data.code != 0) {
              this.$alert(data.msg)
              return
            }
            this.$cookie.set('token',data.token)
            this.$router.replace('/')
            store.setUser(data)
        });
    },
    register () {
      this.$router.push('/register')
    },
    stopCheckQRLogin(){
      this.qrurl = ""
      clearInterval(this.checkLogin);
      clearInterval(this.beatSec);
    },
    querycert () {
      // window.location.href = 'http://36.134.67.33:9085/'
      window.open('http://36.134.67.33:9085/', '_blank')
    }
  }
};
</script>
<style scoped>
.login-form{
    margin-top: 50px;
    width: 460px
}
</style>
